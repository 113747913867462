body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,.menuitem{
    font-family: 'MuseoModerno', cursive;
}
.scale-negativo{
    --tw-scale-x: -1;
}
.animation-call{
    animation: call 3s infinite;
}
.bg-rosa{
    background: lightpink;
}
.color-rosa{
    color: lightpink;
}
.animation-sliderPing{
    animation: sliderPing 2s cubic-beizer(0,0,0.2,1) infinite;
}
@keyframes call{
    from {
        border-color:lightpink;
        color:lightpink;
    }
    to{
        border-color:black;
        color:black;
    }
}

@keyframes sliderPing {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
